import { useContext, useEffect, useState } from "react";
import ValuebetContext from "../../../../../../../context/Valuebet/ValuebetContext";
import { accordionSummaryStyles, handleClickOnMarket } from "../Utils/BetData";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OFFLINE_ODD } from "../../../../../../../constant";
import { getReadablePeriod } from "../../../../../../../Utils/sportsbook";
import CustTooltip from "../../../../../../../Components/CustComp/CustTooltip";



const HomeRunsOdd = ({ odd, team, data, item, title }) => {
    const { betDetails, setBetDetails, removeSelectedBet } = useContext(ValuebetContext);
    const [isSelected, setIsSelected] = useState(false);
    let odd_points = odd.odd_points.toString();
    let odd_money = odd.odd_money.toString();

    useEffect(() => {
        if (betDetails?.bets && betDetails.bets.some((bet) => bet.id === odd?.odd_id)) {
            setIsSelected(true);
        }
        else {
            setIsSelected(false);
        }
        // eslint-disable-next-line
    }, [betDetails])
    return (
        (odd_points === OFFLINE_ODD || odd_points === `+${OFFLINE_ODD}` || odd_money === OFFLINE_ODD) || odd_money === `+${OFFLINE_ODD}` ?
            <div id={odd.id} className="odd-disable-button mt-3"> - </div> :
            (odd?.odd_points || odd?.odd_money) && <div
                role="button"
                className={`d-flex justify-content-between odd-border p-1 rounded-1 bet-container mt-3 ${isSelected ? "selected-odd" : ""}`}
                onClick={() => handleClickOnMarket(team, data, betDetails, setBetDetails, removeSelectedBet, odd, item, title)}
            >
                <div className="header-title ps-2 props-odd-font-size">
                    {odd_points}
                </div>
                <div className="header-title pe-2 props-odd-font-size">
                    {odd?.odd_money > 0 ? `+${odd_money}` : odd_money}
                </div>
            </div>

    )
}

const HomeRuns = ({ member, data, item, title }) => {
    const { period_name } = data;
    const readablePeriodName = getReadablePeriod(period_name);


    return (
        <>
        {member.lines.length>0 && <Box sx={{ mb: 2 }}>
            <Accordion defaultExpanded>
                <AccordionSummary
                    sx={accordionSummaryStyles}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                >
                    <Typography sx={{ fontSize: "16px" }}>{`${member.name} (${data.short_description}) - ${readablePeriodName}`}</Typography>
                    <div className='mt-1'>
                        <CustTooltip id={data.id} text={data.market_description} customClass="d-flex align-items-center ms-2" />
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                        {member.lines.map((line, index) => (
                            <div
                                key={index}
                                style={{
                                    width: 'calc(50% - 5px)',
                                    marginRight: index % 2 === 0 ? '10px' : '0',
                                    marginBottom: '10px' 
                                }}
                            >
                                <HomeRunsOdd odd={line} team={member} data={data} item={item} title={title} />
                            </div>
                        ))}
                    </div>
                </AccordionDetails>

            </Accordion>
        </Box>}
        
        </>


    )

}

export default HomeRuns;