import React from 'react';
import { Container, Card, Row, Col, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Header from '../../../Components/UserHeader';
import Footer from '../../../Components/Footer';
import logoag from '../../../Static/UserManagement/images/common/default-user-avtar.png';
import { BASEURL } from '../../../API-Handle/config';
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';
import UpgradeLevel from './UpgradeLevel';
import PasswordPopup from './PasswordPopup';
import EmailPopup from './EmailPopup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { Warning } from '@mui/icons-material';
import { Tooltip } from 'bootstrap';
import CustTooltip from '../../../Components/CustComp/CustTooltip';

class ProfileSetting extends React.Component {
  componentDidMount() {
    this.context.getUserInfo()
  }

  static contextType = ValuebetContext;
  render() {
    const { userDetails } = this.context;
    return (
      <React.Fragment>
        <Header />
        <div className='sec-header container-fluid'>
          <Row>
            <div className="py-2 d-flex justify-content-start align-items-center">
              <h6 className="sec-header-title">
                Profile settings
              </h6>
            </div>
          </Row>
        </div>
        <div className='layout-mt-65 fill-view'>
        {userDetails ?
          <div className='profile-card-container'>
            <Row className='m-3'>
              <Col sm={12} md={6} lg={6} xl={6}>
                <Row className='justify-content-center text-center mt-5 pt-3'>
                  <Col className="text-center" >
                    <div>
                      {userDetails?.profile_picture ?
                        <img src={BASEURL + userDetails?.profile_picture} alt='Profile Pic' width="160" className="rounded-circle" />
                        : <img src={logoag} width="160" alt='Profile Pic' className="rounded-circle" />}
                    </div>
                    <div>
                      <Link to="/upload-pic" className="btn btn-danger btn-sm btn-orange w-md my-4">
                        Change Picture
                      </Link>
                    </div>

                    <UpgradeLevel />
                  </Col>
                </Row>
              </Col>
              <div className='profile-divider'></div>
              <Col sm={12} md={6} lg={6} xl={6}>
                <Card className='bg-transparent border-0'>
                  <CardBody>
                    <div className='d-flex justify-content-center align-items-center text-white'>
                      <b className='px-2 py-1 border border-danger'>Level - {userDetails?.level_details?.level}</b>
                    </div>
                    <div className='d-flex justify-content-center align-items-center py-2 font-size-11px text-light'>
                      ( {userDetails?.level_details?.level === 1 ? "Default Level" : `${userDetails?.level_details?.remaining_days} Remaining Days`} )
                    </div>
                    <div className='d-flex justify-content-center align-items-center font-size-12px text-white py-1'>
                      <span className='px-2 py-1 border border-danger'> Max Club Host Limit - {userDetails?.level_details?.max_club} </span>
                    </div>
                    <div className='horizantal-line my-3' />
                    <Row>
                      <Col>
                        <div className='ms-3'>
                          <div className="mt-2">
                            <label className='text-light font-size-12px' htmlFor="uid">UID</label>
                            <input className='form-control form-control-sm bg-dark text-white border-secondary' type="text" name="uid"
                              value={userDetails?.id}
                              id="uid" placeholder=""
                              readOnly={true}
                              required />
                          </div>
                          <div className="mt-2">
                            <label className='text-light font-size-12px' htmlFor="username">USERNAME</label>
                            <input className='form-control form-control-sm bg-dark text-white border-secondary' type="text" name="username"
                              value={userDetails?.username}
                              readOnly={true}
                              id="username" placeholder=""
                              required />
                          </div>
                          <div className="mt-2">
                            <label className='text-light font-size-12px' htmlFor="password">PASSWORD</label>
                            <input className='form-control form-control-sm bg-dark text-white border-secondary' type="password" name="password"
                              value={userDetails?.username}
                              readOnly={true}
                              id="password" placeholder=""
                              required />
                          </div>
                          <PasswordPopup />
                          <div>
                            <div className="mt-3">
                              <label className='text-light font-size-12px' htmlFor="email">EMAIL</label>
                              <div className="input-group input-group-sm">
                                <input className='form-control form-control-sm bg-dark text-white border-secondary' type="email" name="email"
                                  value={userDetails?.email}
                                  id="email" placeholder=""
                                  readOnly={true}
                                  required 
                                />
                                <div className="input-group-append">
                                  <span 
                                    className="input-group-text bg-dark text-white rounded-start-0 border-secondary" 
                                    id="basic-addon1" 
                                  >
                                    {userDetails?.is_email_verified ?
                                      <CustTooltip 
                                        id="email-verification"
                                        icon= {<CheckCircleOutlineIcon style={{color: "green", fontSize:20, marginTop:-5}} />}
                                        text={"Verified"}
                                      />:
                                      <CustTooltip 
                                        id="email-verification"
                                        icon= {<Warning style={{color: "yellow", fontSize:20, marginTop:-5}} />}
                                        text={"Unverified"}
                                      />}
                                  </span>
                                </div>
                            </div>
                            </div>
                            <EmailPopup callback={this.context.getUserInfo} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div> : <Container> <Row> <Col className="text-center text-white mt-5 pt-5"> Loading... </Col> </Row> </Container>}
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}


export default ProfileSetting;