import {configureStore} from '@reduxjs/toolkit';
import clubchatSlice from '../features/clubchatSlice';
import sportsbookSlice from '../features/sportsbookSlice';
import valuebetSlice from '../features/valuebetSlice';

export const store = configureStore({
    reducer: {
     clubChat: clubchatSlice,
     sportBook:sportsbookSlice,
     valuebet:valuebetSlice
    },
  });